
import * as Components from "./Californiaeatery"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "californiaeatery"
}
