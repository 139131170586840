
import React, { Component } from 'react';
import './about.scss'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        <h2>Our Story</h2>

        <p>

          <img className="right" src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1543298816/danstuff/pkstory.jpg" alt=""/>
          Pablito’s kitchen was born as an idea that came when I
          saw a need and a demand for a fast casual Peruvian restaurant in LA.
           Most of the restaurants in LA are moms and pops and
           they lack many of the Elements that makes a strong brand.
           Elements like a theme, decor, customer service, consistency in the plates
           and most of all the fast casual experience.

         </p>

          <p>
            {/* <img className="left" src="https://s3-media3.fl.yelpcdn.com/bphoto/Qn91TSYnF_--0BH-DuuPiw/o.jpg" alt=""/> */}
            I approached renowned chef Charles Thomson who at that time was
            based in NYC and asked him if he would like to be part of this journey
            with me and jump on board and the rest is history. We started with a food truck may 2016 and
            from day 1 it was an instant hit not just with Peruvians but with foodies.

          </p>

          <p>
            <img className="right" src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1543298816/danstuff/inside.jpg" alt=""/>

            In October we launched our first brick and mortar in Burbank, Ca.
             We chose Burbank because we felt it was a great midpoint for our first store. Close to
              all the Peruvians that had supported the truck in the San Fernando Valley but still close to
              Glendale, Sun Valley, Pasadena and LA.</p>

          <p>
            <img className="left" src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1543298816/danstuff/158.jpg" alt=""/>
            Opening day we had lines around the corner, most of the city knew about our grand
            opening since we had parked our truck for 2 months in front of where the store would
            be in order for the neighbors to get a taste of what was to come.</p>
      </article>


    </div>
  )
}
