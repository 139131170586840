import React, { Component, PureComponent } from 'react';
import {NavLink, Link} from 'react-router-dom'

import './index.scss'

import OurStoryPage from './OurStoryPage'
// import Catering from './Catering'
import Bagels from './Bagels'
import Donuts from './Donuts'
import Lamil from './Lamil'
import Form from './Form'
export {OurStoryPage as ourstory}
// export {Catering as catering}

export const pages = [
  {path: "/", label: "Order"},
  {path: "/menu", label: "Menu"},
  {path: "/bagels", label: "Bagels", component: Bagels},
  {path: "/donuts", label: "Donuts", component: Donuts},
  {path: "/coffee", label: "Lamil Coffee", component: Lamil},
]

export class SiteNav extends PureComponent {
  render() {

      // {path: "/app", label: "App"},
      // {path: "/our-story", label: "Our Story"}


    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

export const customOrderAppPaths = null //["/menu", "/order"]

export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <Form></Form>

          {/* <div className="text">
            <p><span className="highlight">Start Your Order Below.</span></p>

            <Link to="/order" className="order-online-link">Order Online</Link>
            <p>*Pickup, Delivery, 5% Rewards.</p>
          </div> */}


        </div>



      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
            {/* <h2>When you order directlly</h2> */}
            <p>5% Cashback on Pickup and Delivery.</p>
            <p>Get Free Delivery over $30.</p>
            <p>Start your order below.</p>
          </div>
        </div>
      </div>
    );
  }
}
